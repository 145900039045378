.popup {
    position: relative;
    width: 100%;
    max-width: 780px;
    background: $blue;
    border-radius: 10px;
    &-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding: 15px;
        z-index: 40;
        display: none;
        transition: 0.5s ease;
    }
    &-layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 6;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 40px 20px;
        overflow-y: scroll;
        background: rgba(0, 0, 0, 0.9);
    }
    &-close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 100%;
        max-width: 15px;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover {opacity: 0.7;}
        img {width: 100%;}
    }
    &-cnt {
        &-wrap {
            padding: 50px 20px;
            & h5 {
                font-family: 'Exo 2 Light';
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                color: $white;
                &:not(:last-child) {margin-bottom: 30px;}
                @media (max-width: 1100px) {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }
    & form {
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
    }
}


