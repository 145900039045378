.information {
  margin-bottom: 95px;
  .text{
    a{
      b{
        color:#245F7F;
      }
      &:hover{
        text-decoration: underline;
      }
    }
  }

  &-dealer {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 95px;
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      svg{
        margin-bottom: 15px;
        height: 64px;
      }
    }
    &__title{
      font-family: 'Exo 2 Bold';
      font-size: 16px;
      line-height: 18px;
      text-transform: uppercase;
      color: #456A84;
      max-width: 70%;
      text-align: center;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 45px;
    &-dealer{
      margin-top: 45px;
      flex-wrap: wrap;
      &__item{
        width: 50%;
        padding: 10px;
      }
      &__title{
        max-width: 100%;
        font-size: 14px;
      }
    }
  }
}