.about {
    padding: 67px 0 95px;
    @media (max-width: 1100px) {padding: 61px 0 65px;}
    @media (max-width: 767px) {padding: 52px 0 59px;}
    & .wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
        }
    }
    &-video {
        width: 100%;
        max-width: 445px;
        height: 355px;
        padding: 11px 11px 0;
        margin-right: 25px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top left;
        @media (max-width: 1100px) {
            max-width: 276px;
            height: 220px;
            margin-right: 15px;
        }
        @media (max-width: 767px) {
            max-width: 320px;
            height: 255px;
            margin-right: 0;
            order: 2;
        }
        @media (max-width: 349px) {
            max-width: 280px;
            height: 225px;
        }
        & iframe {
            width: 100%;
            max-width: 422px;
            height: 267px;
            @media (max-width: 1100px) {
                max-width: 261px;
                height: 158px;
            }
            @media (max-width: 767px) {
                max-width: 300px;
                height: 180px;
            }
            @media (max-width: 349px) {
                max-width: 260px;
                height: 162px;
            }
        }
    }
    &-cnt {
        width: 100%;
        @media (max-width: 767px) {
            order: 1;
            margin-bottom: 15px;
        }
        & .title {
            margin-bottom: 37px;
            @media (max-width: 1100px) {margin-bottom: 12px;}
        }
    }
}