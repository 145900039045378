.questions {
    padding: 90px 0 100px;
    background-color: $blue;
    @media (max-width: 1100px) {padding: 61px 0 70px;}
    @media (max-width: 767px) {padding: 52px 0 59px;}
    & .title {margin-bottom: 30px;}
    & form {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-flow: row wrap;
        width: 100%;
        max-width: 730px;
        margin: 0 auto;
    }
}

.form {
    &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    &-field {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 45px;
        margin-bottom: 30px;
        padding: 5px 15px;
        background: $white;
        border: 1px solid $white;
        border-radius: 5px;
        transition: 0.3s ease;
        &.error {border: 1px solid red;}
        @media (max-width: 1100px) {padding: 5px 10px;}
        &-half {
            max-width: 355px;
            @media (max-width: 1100px) {max-width: 336px;}
            @media (max-width: 767px) {max-width: 100%;}
        }
        &-textarea {
            align-items: flex-start;
            padding: 13px 17px;
            min-height: 113px;
            @media (max-width: 1100px) {padding: 13px 10px;}
            & textarea {min-height: 70px;}
        }
        &-img {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 20px;
            margin-right: 20px;
            order: 1;
            @media (max-width: 1100px) {margin-right: 10px; }
            & svg {
                max-width: 100%;
                & path {
                    fill: $darkblue;
                    transition: 0.3s ease;
                }
            }
        }
        & input,
        & textarea {
            order: 2;
            width: 100%;
            outline: none !important;
            border: none !important;
            background: none !important;
            font-family: 'Exo 2 LightItalic';
            font-size: 18px;
            line-height: 18px;
            color: $darkblue;
            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 14px;
            }
            &::-webkit-input-placeholder {color: $darkblue;}
            &::-moz-placeholder {color: $darkblue;}
            &:-ms-input-placeholder {color: $darkblue;}
            &:-moz-placeholder {color: $darkblue;}
            &:focus ~ .form-field-img svg path {fill: $blue;}
        }
        
    }
}