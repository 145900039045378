.header {
    position: relative;
    z-index: 6;
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 6;
    }
    & .logo,
    & nav > a,
    & nav > .menu-section-trigger > a,
    & .header-links {
        position: relative;
        z-index: 9;
    }
    
    &-top {
        position: relative;
        z-index: 7;
        padding: 18px 0;
        background-color: $white;
        border-top: 2px solid $blue;
        @media (max-width: 767px) {border-top: 5px solid $blue;}
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 8;
            display: block;
            background-color: $white;
            width: 100%;
            height: 100%;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        }
        & .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        & .menu-trigger {
            display: none;
            align-items: flex-end;
            justify-content: space-between;
            flex-direction: column;
            padding: 12px 9px;
            transition: 0.6s ease;
            @media (max-width: 1100px) {display: flex;}
            & span {
                display: block;
                width: 100%;
                height: 2px;
                background-color: $white;
                transition: 0.2s cubic-bezier(0, 0, 0.2, 1);
                border-radius: 2px;
                &:nth-child(2) {width: 75%;}
            }
        }
        & .lang-trigger {
            @media (max-width: 1100px) {display: none;}
        }
        & .header-link {
            @media (max-width: 767px) {display: none;}
        }
        & .cabinet-trigger {
            @media (max-width: 767px) {display: flex;}
        }
    }
    &.open  {
        & .header-top {
            & .menu-trigger {
                justify-content: center;
                & span {
                    width: 100%;
                    &:nth-child(1) {
                        transform: rotate(-40deg);
                        margin-bottom: -2px;
                    }
                    &:nth-child(2) {display: none;}
                    &:nth-child(3) {transform: rotate(40deg);}
                }
            }
        }
    }
    & .logo {
        display: block;
        width: 100%;
        max-width: 153px;
        @media (max-width: 1100px) {max-width: 118px;}
        @media (max-width: 767px) {max-width: 125px;}
        & img {max-width: 100%;}
    }
    & .nav-menu-wrap {
        @media (max-width: 1100px) {
            position: absolute;
            top: 0;
            right: 0;
            display: none;
            width: 100%;
            height: 100vh;
            background: rgba(36, 95, 127, 0.9);
            &-layout {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
            }
        }
    }
    & nav {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media (max-width: 1100px) {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            max-width: 375px;
            height: 100vh;
            padding: 112px 15px 100px;
            background-color: $white;
            overflow-y: scroll;
            z-index: 5;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        }
        & .nav-link-triggers {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            width: 100%;
            margin-top: 35px;
            @media (min-width: 1101px) {display: none;}
            & div {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 30px;
            }
            & .header-link {
                display: flex;
                &:not(:last-child) {margin-right: 10px;}
            }
        }
        & > a,
        & .menu-section-trigger > a {
            position: relative;
            display: block;
            font-family: 'Tahoma Regular';
            font-size: 15px;
            line-height: 15px;
            color: $text;
            transition: 0.3s ease;
            @media (max-width: 1100px) {
                padding: 10px 20px;
            }
            @media (max-width: 500px) {
                max-width: 100%;
            }
            &:not(:last-child) {
                margin-right: 23px;
                @media (max-width: 1100px) {margin: 0 0 5px;}
            }
            &::before {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                display: block;
                width: 100%;
                height: 37px;
                border-bottom: 3px solid #00B1CE;
                background-color: transparent;
                opacity: 0;
                @media (max-width: 1100px) {display: none;}
            }
            &:hover::before {opacity: 1;}
        }
    }
    &-links {
        display: flex;
        align-items: center;
        justify-content: center;
        & .header-link:not(:last-child) {
            margin-right: 17px;
            @media (max-width: 1100px) {margin-right: 20px;}
            @media (max-width: 767px) {margin-right: 15px;}
        }
    }
}

.link-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    padding: 3px;
    background-color: $white;
    border: 1px solid $border;
    border-radius: 5px;
    transition: 0.3s ease;
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: none !important;
        outline: none;
    }
    & svg {
        max-width: 100%;
        & path {transition: 0.3s ease;}
    }
    &:hover {
        background-color: $blue;
        border: 1px solid $blue;
        & svg path {fill: $white;}
    }
    &:hover::before {opacity: 1;}
    &-blue {
        background-color: $blue;
        border: 1px solid $blue;
        & svg path {fill: $white;}
        @media (min-width: 1101px) {
            &:hover {
                background-color: $white;
                border: 1px solid $border;
                & svg path {fill: $blue;}
            }
        }
    }
}

.link-select {
    position: relative;
    & span,
    & a {
        font-family: 'Tahoma Regular';
        font-size: 15px;
        line-height: 15px;
        color: $text;
        transition: 0.3s ease;
    }
    &-big {
        width: 100%;
        max-width: 254px;
        & .link-select-active {
            width: 100%;
            justify-content: space-between;
            padding: 3px 17px;
        }
        & .link-select-hidden {
            padding: 10px 17px;
            & a {text-align: left;}
        }
    }
    &-active {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        min-width: 61px;
        padding: 3px;
        background-color: $white;
        border: 1px solid $border;
        border-radius: 5px;
        transition: 0.3s ease;
        & span {margin-right: 6px;}
        & svg {
            max-width: 10px;
            transition: 0.3s ease;
            margin-top: 2px;
            & path {transition: 0.3s ease;}
        }
        @media (min-width: 1101px) {
            &:hover {
                background-color: $blue;
                border: 1px solid $blue;
                & span {color: $white;}
                & svg {
                    & path {
                        fill: $white;
                        stroke: $white;
                    }
                }
            }
        }
    }
    &-hidden {
        position: absolute;
        top: calc(100% + 3px);
        left: 0;
        z-index: 3;
        display: none;
        width: 100%;
        padding: 10px 5px;
        background-color: $white;
        border: 1px solid $border;
        border-radius: 5px;
        & a {
            display: block;
            text-align: center;
            &:not(:last-child) {margin-bottom: 10px;}
            &:hover {color: $blue;}
        }
    }
    &.active {
        & .link-select-active {
            background-color: $blue;
            border: 1px solid $blue;
            & span {color: $white;}
            & svg {
                transform: rotate(180deg);
                & path {
                    fill: $white;
                    stroke: $white;
                }
            }
        }
    }
}

.menu {
    &-section {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        background-color: $white;
        z-index: 5;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        background-image: url('../images/logo-big-brand.png');
        background-repeat: no-repeat;
        background-position: right 236px bottom 39px;
        background-size: 338px;
        @media (max-width: 1600px) {background-position: right 236px bottom 39px;}
        @media (max-width: 1100px) {
            position: static;
            display: none;
            background-image: none;
            box-shadow: none;
        }
        & .wrapper {
            padding-top: 112px;
            padding-bottom: 100px;
            background-repeat: no-repeat;
            background-position: right 0 bottom 0;
            background-size: 245px auto;
            transition: 0.3s ease;
            @media (max-width: 1600px) {background-position: right 236px bottom 39px;}
            @media (max-width: 1100px) {
                padding: 0;
                background-image: none;
            }
        }
        &-trigger {
            @media (max-width: 1100px) {
                width: 100%;
                max-width: 300px;
                margin-bottom: 5px;
            }
            @media (max-width: 500px) {
                max-width: 100%;
            }
            @media (min-width: 1101px) {
                &:hover {
                    & > a {
                        color: $blue !important;
                        &::before {opacity: 1 !important;}
                    }
                    & .menu-section {display: block;}
                }
            }
            & a {
                display: flex !important;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                max-width: 300px;
                svg {
                    display: none;
                    transition: 0.2s ease;
                    transform: rotate(180deg);
                    @media (max-width: 1100px) {display: block;}
                    & path {
                        fill: $text;
                        transition: 0.2s ease;
                    }
                }
            }
            & > a {
                @media (max-width: 1100px) {
                    margin-bottom: 0 !important;
                }
            }
            @media (max-width: 1100px) {
                &.active {
                    & > a {
                        background-color: $text;
                        color: $white !important;
                        svg {
                            transform: rotate(0);
                            & path {fill: $white;}
                        }
                    }
                    & .menu-section {display: block;}
                }
            }
        }
    }
    &-row {
        display: flex;
        align-items: stretch;
        justify-items: flex-start;
        width: 100%;
    }
    &-hidden {
        &-section {
            display: none;
            width: 100%;
            padding: 20px;
            max-width: 622px;
            background: #FAFAFA;
            border-radius: 10px;
            @media (max-width: 1100px) {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                max-width: 375px;
                height: 100vh;
                padding: 112px 15px 100px;
                background-color: #ffffff;
                overflow-y: scroll;
                z-index: 10;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
            }
            &-title {
                display: block;
                margin-bottom: 25px;
                font-family: 'Exo 2 Bold';
                font-size: 18px;
                line-height: 18px;
                color: $text; 
                @media (max-width: 1100px)  {display: none;}
                &-mob {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 25px;
                    font-family: 'Exo 2 Bold';
                    font-size: 15px;
                    line-height: 15px;
                    color: $text; 
                    & svg {
                        transform: rotate(-90deg);
                        margin-right: 8px;
                        max-width: 9px;
                        & path {fill: $text;}
                    }
                    @media (min-width: 1101px)  {display: none;}
                }
            }
        }
        &-list {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @media (max-width: 1100px) {
                flex-direction: column;
                align-items: flex-start;
            }
            &-category {
                width: 100%;
                max-width: 270px;
                margin-right: 13px;
                @media (max-width: 1100px) {
                    max-width: 100%;
                    margin-right: 0;
                }
                & .show-hidden-menu {
                    & > a {
                        @media (max-width: 1100px) {
                            display: block;
                            max-width: 100%;
                            margin: 0 !important;
                            padding: 10px 20px;
                            transition: 0.3s ease;
                            font-size: 15px;
                            line-height: 15px;
                            font-family: 'Exo 2 Bold';
                        }
                        svg {
                            display: none;
                            transition: 0.2s ease;
                            transform: rotate(180deg);
                            @media (max-width: 1100px) {display: block;}
                            & path {
                                fill: $text;
                                transition: 0.2s ease;
                            }
                        }
                    }
                    &.active {
                        @media (max-width: 1100px) {
                            & > a {
                                background-color: $text;
                                color: $white !important;
                                svg {
                                    transform: rotate(0);
                                    & path {fill: $white;}
                                }
                            }
                            & .menu-hidden-list-category-item {display: block !important;}
                        }
                    }
                }
                &-item {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: none;
                    width: 100%;
                    max-width: 297px;
                    padding: 18px 25px;
                    background: $white;
                    border-radius: 10px;
                    @media (max-width: 1100px) {
                        position: static;
                        max-width: 100%;
                        padding: 14px 0;
                        background: #FAFAFA;
                        border-radius: 0;
                        margin-bottom: 10px;
                        &  a {
                            display: block;
                            padding: 0 20px 0 38px;
                        }
                    }
                    &.hidden {
                        visibility: hidden;
                        display: block !important;
                    }
                    &:not(:last-child) {
                        margin-bottom: 10px !important;
                        @media (max-width: 1100px)  {margin-bottom: 5px !important;}
                    }
                }
            }
            & a {
                display: block;
                font-family: 'Exo 2 Light';
                font-size: 18px;
                line-height: 18px;
                color: $text;
                transition: 0.3s ease;
                @media (min-width: 1101px) {
                    &:hover {color: $blue;}
                }
                @media (max-width: 1100px) {
                    font-size: 15px;
                    line-height: 15px;
                }
                &.active {color: $blue;}
                &:not(:last-child) {
                    margin-bottom: 15px;
                    @media (max-width: 1100px)  {margin-bottom: 20px;}
                }
            }
        }
    }
    &-column {
        width: 100%;
        max-width: 160px;
        margin-right: 30px;
        padding: 14px 0 25px;
        background: #FAFAFA;
        border-radius: 10px;
        @media (max-width: 1100px) {
            max-width: 100%;
            margin: 0 0 8px;
            border-radius: 0;
        }
    }
    &-content {
        &-trigger {
            display: block;
            margin-bottom: 15px;
            font-family: 'Exo 2 Bold';
            font-size: 18px;
            line-height: 18px;
            text-align: center;
            color: $text;
            @media (max-width: 1100px) {
                text-align: left;
                padding-left: 40px;
            }
        }
        & a {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 33px;
            padding: 5px 15px;
            border-right: 2px solid transparent;
            transition: 0.3s ease;
            @media (max-width: 1100px) {
                justify-content: space-between;
                padding-left: 40px;
            }
            svg {
                display: none;
                transition: 0.2s ease;
                margin-left: 10px;
                transform: rotate(90deg);
                @media (max-width: 1100px) {display: block;}
                & path {
                    fill: $text;
                    transition: 0.2s ease;
                }
            }
            &:not(:last-child) {margin-bottom: 5px;}
            & img {
                max-width: 100%;
                max-height: 100%;
            }
            &::before {
                content: '';
                position: absolute;
                top: calc(50% - 6px);
                left: 100%;
                width: 0; 
                height: 0; 
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-left: 6px solid $blue;
                transition: 0.3s ease;
                opacity: 0;
                @media (max-width: 1100px) {display: none;}
            }
            &.active {
                border-right: 2px solid $blue;
                &::before {opacity: 1;}
            }
        }
    }
}