.btn-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: 132px;
    padding: 4px;
    font-family: 'Franklin Gothic';
    font-size: 18px;
    line-height: 18px;
    color: $white;
    background: #84BDCA;
    border: 1px solid $border;
    border-radius: 5px;
    transition: 0.3s ease;
    &:hover {
        background: transparent;
        color: $blue;
    }
}

.btn {
    display: inline-block;
    height: 45px;
    padding: 0 52px;
    font-family: 'Exo 2 Medium';
    font-size: 18px;
    line-height: 41px;
    text-align: center;
    color: $blue;
    border: 1px solid $blue;
    border-radius: 5px;
    transition: 0.3s ease;
    &:hover {
        color: $white;
        background-color: $blue;
    }
    &-blue {
        border: 2px solid $blue;
        background-color: $blue;
        color: $white;
        @media (min-width: 1101px) {
            &:hover {
                background-color: transparent;
                color: $blue;
                & span {color: $blue;}
                & svg {
                    & path {
                        fill: $blue;
                        stroke: $blue;
                    }
                }
            }
        }
    }
    &-big {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 285px;
        min-height: 86px;
        line-height: 18px;
        border-radius: 20px;
        @media (max-width: 1100px) {
            max-width: 350px;
            min-height: 45px;
            border-radius: 5px;
        }
    }
    &-dark {
        color: $white;
        border: 2px solid $darkblue;
        background-color: $darkblue;
        & svg {
            margin-right: 11px;
            & path {
                fill: $white;
                transition: 0.3s ease;
            }
        }
        &:hover {
            color: $darkblue !important;
            background: transparent !important;
            & svg {
                & path {fill: $darkblue; }
            }
        }
    }
    &-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 160px;
        padding: 0 15px;
    }
    &-dashed {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 160px;
        height: 45px;
        border: 1px dashed $darkblue;
        border-radius: 5px;
        font-family: 'Exo 2 Medium';
        font-size: 18px;
        line-height: 18px;
        color: $darkblue;
        transition: 0.3s ease;
        @media (max-width: 1360px) {
            max-width: 135px;
            height: 34px;
            font-size: 14px;
            line-height: 14px;
        }
        &:hover {
            border: 1px solid $darkblue;
            background-color: $darkblue;
            color: $white;
        }
    }
    @media (max-width: 1100px) {
        height: 40px;
        font-size: 14px;
        line-height: 36px;
    }
}