.categories {
    padding: 100px 0 88px;
    @media (max-width: 1100px) {padding: 65px 0;}
    @media (max-width: 767px) {padding: 53px 0 30px;}
    & .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &-head {
        width: 100%;
        max-width: 315px;
        padding-right: 15px;
        margin-bottom: 30px;
        @media (max-width: 1100px) {
            max-width: 100%;
            margin-bottom: 18px;  
        }
        @media (max-width: 900px) { max-width: 205px;}
        @media (max-width: 767px) {
            max-width: 100%;
            margin-bottom: 15px; 
            padding-right: 0;
        }
    }
    &-list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-flow: row wrap;
        width: 100%;
        max-width: 724px;
        @media (max-width: 1100px) { max-width: 464px;}
        @media (max-width: 767px) { 
            justify-content: flex-start;
            max-width: 100%;
        }
        @media (max-width: 540px) { justify-content: space-between;}
    }
    &-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 224px;
        height: 83px;
        margin-bottom: 30px;
        padding: 5px 10px 5px 15px;
        background: $white;
        border: 1px solid $border;
        border-radius: 5px;
        transition: 0.3s ease;
        @media (max-width: 1100px) {
            max-width: 142px;
            height: 52px;
            margin-bottom: 18px;
            padding: 5px 5px 5px 3px;
        }
        @media (max-width: 767px) {
            max-width: 152px;
            height: 56px;
            margin-bottom: 20px;
            padding: 5px 5px 5px 3px;
            &:not(:last-child) {margin-right: 15px;}
        }
        @media (max-width: 540px) { 
            &:not(:last-child) {margin-right: 0;}
        }
        &-img {
            position: relative;
            display: block;
            min-width: 61px;
            width: 61px;
            height: 61px;
            max-width: 61px;
            margin-right: 8px;
            @media (max-width: 1100px) {
                min-width: 38px;
                width: 38px;
                height: 38px;
                max-width: 38px;
            }
            & img {
                position: absolute;
                top: 0;
                left: 0;
                max-width: 100%;
                transition: 0.3s ease;
            }
            &-active {opacity: 1;}
            &-hover {opacity: 0;}
        }
        &-title {
            font-family: 'Exo 2 Bold';
            font-size: 18px;
            line-height: 18px;
            color: $text;
            transition: 0.3s ease;
            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 17px;
            }
        }
        &:hover {
            background-color: $darkblue;
            border: 1px solid $darkblue;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
            & .categories-item-title {color: $white;}
            & .categories-item-img {
                &-active {opacity: 0;}
                &-hover {opacity: 1;}
            }
        }
    }
}