.card {
    &-head {
        padding-bottom: 84px;
        & .wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @media (max-width: 767px) {
                flex-direction: column;
                align-items: center;
            }
        }
        &-info {
            width: 100%;
            max-width: 635px;
            margin: -6px 0 0;
            &-hidden {
                width: 100%;
                max-width: 100%;
                margin: 0;
                display: none;
                @media (max-width: 767px) {
                    display: block;
                    order: 3;
                    & .btn {
                        margin: 0 auto 30px;
                    }
                    & .card-benefits {
                        width: calc(100% + 40px);
                        margin: 0 0 0 -20px;
                    }
                    & .info-block {display: block;}
                }
            }
            @media (max-width: 767px) {
                margin: 0 0 30px;
                max-width: 100%;
                order: 1;
                & .card-benefits,
                & .btn,
                & .info-block {display: none;}
            }
            & .text {
                width: 100%;
                margin-bottom: 10px;
                @media (max-width: 767px) {margin-bottom: 0;}
            }
        }
    }
    &-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 11px;
        & .title {
            width: 100%;
            margin: 0 0 0;
        }
        &-num {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 113px;
            max-width: 200px;
            min-height: 48px;
            padding: 5px 15px;
            font-family: 'Exo 2 Regular';
            font-size: 18px;
            line-height: 18px;
            color: $text;
            border: 1px solid $text;
            border-radius: 20px 0px 20px 20px;
            @media (max-width: 1100px) {
                padding: 5px 5px;
                min-width: 73px;
                max-width: 120px;
                min-height: 31px;
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
    &-benefits {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 27px;
        padding: 50px 15px;
        width: 100%;
        background: #FAFAFA;
        border-radius: 20px;
        @media (max-width: 767px) {
            margin-bottom: 0;
            padding: 20px;
        }
        &-cnt {
            display: block;
            width: 100%;
            max-width: 388px;
            @media (max-width: 767px) {max-width: 100%;}
        }
    }
    &-bnf {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        &:not(:last-child) {margin-bottom: 25px;}
        &-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 60px;
            min-width: 60px;
            margin-right: 30px;
            @media (max-width: 767px) {margin-right: 20px;}
            & img {max-width: 100%;}
        }
        &-cnt {
            display: block;
            width: 100%;
            max-width: 300px;
            margin: 0;
            @media (max-width: 767px) {max-width: 100%;}
        }
    }
    &-slider {
        width: 100%;
        max-width: 445px;
        margin-right: 30px;
        @media (max-width: 1100px) {
            max-width: 320px;
            margin-right: 15px;
        }
        @media (max-width: 767px) {
            order: 2;
            margin: 0 0 30px;
        }
        &-images {
            width: 100%;
            margin-bottom: 30px;
            border-radius: 10px;
            overflow: hidden;
            @media (max-width: 1100px) {margin-bottom: 20px;}
            & .owl-nav {
                transition: 0.3s ease;
                opacity: 0;
                @media (max-width: 1100px) {opacity: 1;}
                & button {
                    position: absolute;
                    top: calc(50% - 16px);
                    width: 50px;
                    height: 50px;
                    background-repeat: no-repeat !important;
                    background-position: center !important;
                    background-size: 16px !important;
                    transition: 0.3s ease;
                    &:hover {opacity: 0.7;}
                    & span {display: none;}
                    &.owl-next {
                        right: 0;
                        background-image: url('../images/arrow-right-dark.svg') !important;
                    }
                    &.owl-prev {
                        left: 0;
                        background-image: url('../images/arrow-left-dark.svg') !important;
                    }
                }
            }
            &:hover .owl-nav {opacity: 1;}
            &-dots {
                width: 100%;
                & .owl-item{
                    @media (max-width: 992px) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &:hover {
                        & .card-slider-dots-img {border:  1px solid $blue !important; }
                    }
                }

                & .owl-nav {
                    & button {
                        position: absolute;
                        top: calc(50% - 7px);
                        display: block;
                        width: 14px;
                        height: 14px;
                        background-image: url('../images/slider-arrow.svg') !important;
                        background-repeat: no-repeat !important;
                        background-position: center !important;
                        background-size: contain !important;
                        &.owl-prev {
                            left: 0;
                            transform: rotate(-180deg);
                            background-image: url('../images/arrow-right-dark.svg') !important;
                        }
                        &.owl-next {
                            right: 0;
                            transform: rotate(0deg);
                            background-image: url('../images/arrow-right-dark.svg') !important;
                        }
                        & span {display: none;}
                    }
                }
            }
        }
        &-img {
            display: flex;
            align-items: flex-end;
            justify-content: center;
           // min-height: 453px;
            min-height: 445px;
            max-height: 445px;
            width: 100%;
            background-color: #C7E7F2;
            overflow: hidden;
            @media (max-width: 767px) {
                min-height: 320px;
                max-height: 320px;
            }
            & img {
                width: auto !important;
                max-width: 100%;
                max-height: 100%;
                border: 2px solid #C7E7F2;
                border-radius: 10px;
            }
        } 
        &-dots {
            &-img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 126px;
                //height: 86px;
                height: 126px;
                background-color: #C7E7F2 !important;
                border: 1px solid #C7E7F2;
                border-radius: 10px;
                overflow: hidden;
                transition: 0.3s ease;
                @media (max-width: 1100px) {
                    max-width: 90px;
                    height: 90px;
                }
                & img {
                    width: auto !important;
                    max-width: 100%;
                    max-height: 100%;
                }
                &:hover {border:  1px solid $blue !important; }
            }
        }
    }
    &-info {
        &-content {
            display: block;
            padding: 50px 0 75px;
            background-color: #FAFAFA;
        }
    }
}