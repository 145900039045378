.pagination{
  list-style: none;
  padding: 0;
  margin: 40px -5px 0px -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  >li{
    margin-left: 5px;
    margin-right: 5px;
    .btn{
      width: 30px;
      height: 30px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    &.active{
      .btn{
        color: #245F7F !important;
        background: transparent !important;
      }
    }
  }
}