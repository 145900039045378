// Franklin
@font-face {
    font-family: 'Franklin Gothic';
    src: url('./fonts/frand/FranklinGothic-Medium.woff2') format('woff2'),
        url('./fonts/frand/FranklinGothic-Medium.woff') format('woff'),
        url('./fonts/frand/FranklinGothic-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Franklin Gothic Book';
    src: url('./fonts/frand/FranklinGothic-Book.woff2') format('woff2'),
         url('./fonts/frand/FranklinGothic-Book.woff') format('woff'),
         url('./fonts/frand/FranklinGothic-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}




// Tahoma

@font-face {
    font-family: 'Tahoma Regular';
    src: url('./fonts/tahoma/Tahoma.woff2') format('woff2'),
        url('./fonts/tahoma/Tahoma.woff') format('woff'),
        url('./fonts/tahoma/Tahoma.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


// bebas

@font-face {
    font-family: 'Bebas Neue';
    src: url('./fonts/bebas/BebasNeueRegular.woff2') format('woff2'),
        url('./fonts/bebas/BebasNeueRegular.woff') format('woff'),
        url('./fonts/bebas/BebasNeueRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}




// exo

// used

@font-face {
    font-family: 'Exo 2 Medium';
    src: url('./fonts/exo/Exo2-Medium.woff2') format('woff2'),
        url('./fonts/exo/Exo2-Medium.woff') format('woff'),
        url('./fonts/exo/Exo2-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 Bold';
    src: url('./fonts/exo/Exo2-Bold.woff2') format('woff2'),
        url('./fonts/exo/Exo2-Bold.woff') format('woff'),
        url('./fonts/exo/Exo2-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 Light';
    src: url('./fonts/exo/Exo2-Light.woff2') format('woff2'),
        url('./fonts/exo/Exo2-Light.woff') format('woff'),
        url('./fonts/exo/Exo2-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Exo 2 LightItalic';
    src: url('./fonts/exo/Exo2-LightItalic.woff2') format('woff2'),
        url('./fonts/exo/Exo2-LightItalic.woff') format('woff'),
        url('./fonts/exo/Exo2-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 Regular';
    src: url('./fonts/exo/Exo2-Regular.woff2') format('woff2'),
        url('./fonts/exo/Exo2-Regular.woff') format('woff'),
        url('./fonts/exo/Exo2-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}














@font-face {
    font-family: 'Exo 2 ThinItalic';
    src: url('./fonts/exo/Exo2-ThinItalic.woff2') format('woff2'),
        url('./fonts/exo/Exo2-ThinItalic.woff') format('woff'),
        url('./fonts/exo/Exo2-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 Thin';
    src: url('./fonts/exo/Exo2-Thin.woff2') format('woff2'),
        url('./fonts/exo/Exo2-Thin.woff') format('woff'),
        url('./fonts/exo/Exo2-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 Black';
    src: url('./fonts/exo/Exo2-Black.woff2') format('woff2'),
        url('./fonts/exo/Exo2-Black.woff') format('woff'),
        url('./fonts/exo/Exo2-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 BlackItalic';
    src: url('./fonts/exo/Exo2-BlackItalic.woff2') format('woff2'),
        url('./fonts/exo/Exo2-BlackItalic.woff') format('woff'),
        url('./fonts/exo/Exo2-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 BoldItalic';
    src: url('./fonts/exo/Exo2-BoldItalic.woff2') format('woff2'),
        url('./fonts/exo/Exo2-BoldItalic.woff') format('woff'),
        url('./fonts/exo/Exo2-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 ExtraBold';
    src: url('./fonts/exo/Exo2-ExtraBold.woff2') format('woff2'),
        url('./fonts/exo/Exo2-ExtraBold.woff') format('woff'),
        url('./fonts/exo/Exo2-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 ExtraLight';
    src: url('./fonts/exo/Exo2-ExtraLight.woff2') format('woff2'),
        url('./fonts/exo/Exo2-ExtraLight.woff') format('woff'),
        url('./fonts/exo/Exo2-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 SemiBold';
    src: url('./fonts/exo/Exo2-SemiBold.woff2') format('woff2'),
        url('./fonts/exo/Exo2-SemiBold.woff') format('woff'),
        url('./fonts/exo/Exo2-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 ExtraBoldItalic';
    src: url('./fonts/exo/Exo2-ExtraBoldItalic.woff2') format('woff2'),
        url('./fonts/exo/Exo2-ExtraBoldItalic.woff') format('woff'),
        url('./fonts/exo/Exo2-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 MediumItalic';
    src: url('./fonts/exo/Exo2-MediumItalic.woff2') format('woff2'),
        url('./fonts/exo/Exo2-MediumItalic.woff') format('woff'),
        url('./fonts/exo/Exo2-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 ExtraLightItalic';
    src: url('./fonts/exo/Exo2-ExtraLightItalic.woff2') format('woff2'),
        url('./fonts/exo/Exo2-ExtraLightItalic.woff') format('woff'),
        url('./fonts/exo/Exo2-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 SemiBoldItalic';
    src: url('./fonts/exo/Exo2-SemiBoldItalic.woff2') format('woff2'),
        url('./fonts/exo/Exo2-SemiBoldItalic.woff') format('woff'),
        url('./fonts/exo/Exo2-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2 Italic';
    src: url('./fonts/exo/Exo2-Italic.woff2') format('woff2'),
        url('./fonts/exo/Exo2-Italic.woff') format('woff'),
        url('./fonts/exo/Exo2-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
