.footer {
    position: relative;
    &-row {
        display: flex;
        justify-content: space-between;
        padding: 106px 0 104px;
        @media (max-width: 992px) {
            align-items: flex-start;
            justify-content: center;
            flex-flow: row wrap;
            padding: 70px 0 35px;
        }
        @media (max-width: 767px)  {padding: 48px 0 50px;}
    }
    &-col {
        width: 100%;
        max-width: 200px;
        padding-right: 15px;
        border-right: 1px solid #DAE1E6;
        @media (max-width: 992px) {
            margin-bottom: 35px;
            &:nth-child(3) {border: none;}
        }
        @media (max-width: 767px) {
            max-width:115px;
            &:nth-child(2) {
                max-width: 165px;
                padding-right: 30px;
                margin-right: 30px;
            }
        }
        @media (max-width: 349px) {
            &:nth-child(2) {
                max-width: 150px;
                padding-right: 15px;
                margin-right: 15px;
            }
        }
        &:first-child,
        &:last-child {border: none;}
        & h5 {
            margin-bottom: 20px;
            font-family: 'Exo 2 Bold';
            font-size: 16px;
            line-height: 16px;
            text-transform: uppercase;
            color: $text;
        }
        &.footer-info {
            @media (max-width: 992px) {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;
                max-width: 100%;
            }
            @media (max-width: 767px) {
                flex-direction: column;
                align-items: center;
                padding-right: 0;
            }
        }
    }
    &-first {
        @media (max-width: 767px) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            max-width: 100%;
            margin-bottom: 23px;
            padding-right: 0;
        }
    }
    &-nav {
        & a {
            display: block;
            font-family: 'Exo 2 Regular';
            font-size: 16px;
            line-height: 16px;
            color: $text;
            transition: 0.3s ease;
            &:hover {color: $blue;}
            &:not(:last-child) {margin-bottom: 12px;}
        }
    }
    &-logo {
        display: block;
        width: 100%;
        max-width: 153px;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            max-width: 210px;
            margin-bottom: 15px;
        }
        & img {width: 100%;}
    }
    &-bottom {
        padding: 13px 0;
        font-family: 'Exo 2 Regular';
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: #FFFFFF;
        background-color: $darkblue;
    }
    & .social {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        & .link-block {
            &:not(:last-child) {margin-right: 10px;}
        }
    }
}

.link-row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: 992px) {
        width: 100%;
        max-width: 200px;
    }
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
    }
    &:not(:last-child) {
        margin-bottom: 23px;
    }
    &-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        min-width: 15px;
        margin-right: 6px;
        transform: translateY(1px);
        @media (max-width: 767px)  {
            margin: 0 0 6px;
        }
        & img {max-width: 100%;}
    }
    &-cnt {
        & p,
        & a {
            display: block;
            font-family: 'Exo 2 Regular';
            font-size: 16px;
            line-height: 28px;
            color: $text;
            transition: 0.3s ease;
            @media (max-width: 767px) {
                width: 100%;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
            }
        }
        & a {
            line-height: 16px;
            &:hover {color: $blue;}
            &:not(:last-child) {margin-bottom: 12px;}
        }
    }
}

.scroll-top {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 10;
    display: none;
    width: 100%;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    background-color: $darkblue;
    border-radius: 5px;
    transition: 0.3s ease;
    @media (max-width: 767px) {
        position: absolute;
        top: -20px;
        left: calc(50% - 20px);
    }
    &:hover {background-color: $blue;}
    & span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    & img {max-width: 16px;}
}