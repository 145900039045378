.catalog {
    &-block {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 54px;
    }
    &-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 41px;
        padding: 34px 42px 24px;
        background-color: $white;
        border: 1px solid $border;
        border-radius: 10px;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 25px;
            padding: 20px 15px 15px;
        }
        & img {
            max-width: 264px;
            margin-right: 15px;
            @media (max-width: 767px) {
                max-width: 155px;
               margin: 0 0 10px;
            }
        }
        & p {
            width: 100%;
            max-width: 689px;
            @media (max-width: 767px) {text-align: center;}
        }
    }
    &-filter {
        width: 100%;
        max-width: 255px;
        @media (max-width: 767px) {
            padding-top: 20px;
            max-width: 276px;
            background-color: $white;
            border-radius: 10px 0 0 10px;
            overflow: hidden;
        }
        & #ocfilter {
            & .catalog-filter-buttons {
                display: none;
                @media (max-width: 767px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    padding: 0 0 35px;
                    & .btn {margin-bottom: 17px;}
                    & .reset {
                        display: inline-block;
                        font-family: 'Exo 2 Medium';
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        border-bottom: 1px solid $darkblue;
                        background: none !important;
                        outline: none !important;
                        color: $darkblue;
                    }
                }
            }
        }
        &-layout {
            width: 100%;
            max-width: 255px;
            margin-right: 30px;
            @media (max-width: 767px) {
                position: absolute;
                top: 0;
                right: 0;
                display: none;
                z-index: 40;
                padding-top: 0;
                padding-top: 15px;
                padding-bottom: 50px;
                width: 100%;
                height: 100vh;
                overflow-y: scroll;
                max-width: 100%;
                margin-right: 0;
                background: rgba(36, 95, 127, 0.9);
            }
        }
        &-wrap {
            @media (max-width: 767px) {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
            }
        }
        &-row {
            width: 100%;
            & .form-filter {
                width: 100%;
                padding: 9px 16px 10px;
                & p {
                    font-family: 'Exo 2 Bold';
                    font-size: 16px;
                    line-height: 16px;
                    color: $darkblue;
                    &:not(:last-child) {margin-bottom: 10px;}
                }
            }
        }
        &-column {
            width: 100%;
            background: $white;
            border: 1px solid $border;
            border-radius: 10px;
            overflow: hidden;
            @media (max-width: 767px) {
                border: 0px solid $border;
                border-radius: 0;
                &:not(:last-child) {
                    margin-bottom: 0;
                    padding-bottom: 25px;
                    border-bottom: 1px solid $border;
                }
            }
            &:not(:last-child) {
                margin-bottom: 30px;
                @media (max-width: 767px) {margin-bottom: 30px;}
            }
            & .catalog-filter-row:last-child .catalog-filter-row-hidden {border: none;}
        }
        &-link {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            min-height: 29px;
            padding: 5px 10px 5px 16px;
            font-family: 'Exo 2 Medium';
            font-size: 14px;
            line-height: 14px;
            color: $text;
            transition: 0.3s ease;
            &:hover {
                color: $white;
                background-color: $blue;
            }
        }
        &-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            min-height: 52px;
            padding: 5px 16px;
            font-family: 'Franklin Gothic';
            font-size: 18px;
            line-height: 18px;
            color: $darkblue;
            border-bottom: 1px solid $border;
            @media (max-width: 767px) {
                min-height: 0;
                padding: 0 16px;
                padding-bottom: 20px;
                border: none;
            }
        }
        &-row {
            width: 100%;
            &-trigger {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                min-height: 38px;
                padding: 5px 9px 5px 16px;
                font-family: 'Exo 2 Medium';
                font-size: 16px;
                line-height: 16px;
                color: $text;
                transition: 0.3s ease;
                & svg {
                    width: 12px;
                    max-width: 12px;
                    margin-left: 5px;
                    transition: 0.3s ease;
                    & path {
                        fill: $text;
                        transition: 0.3s ease;
                    }
                }
                &:hover {
                    background-color: $darkblue;
                    color: $white;
                    & svg {
                        & path {fill: $white;}
                    }
                }
                &.active {
                    background-color: $darkblue;
                    color: $white;
                    & svg {
                        transform: rotate(180deg);
                        & path {fill: $white;}
                    }
                }
            }
            &-hidden {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                width: 100%;
                background: #FAFAFA;
                border-bottom: 1px solid $border;
                &-wrap {display: none;}
                & a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    min-height: 28px;
                    padding: 5px 10px 5px 35px;
                    font-family: 'Exo 2 Light';
                    font-size: 14px;
                    line-height: 14px;
                    color: $text;
                    transition: 0.3s ease;
                    &::before {
                        content: '';
                        position: absolute;
                        top: calc(50% - 4px);
                        left: 16px;
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $blue;
                        transition: 0.3s ease;
                    }
                    &:hover {
                        color: $white;
                        background-color: $blue;
                        &::before {background-color: $white;}
                    }
                }
            }
        }
    }
    &-list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: row wrap;
        width: 100%;
        max-width: 824px;
        &-wrap {width: 100%;}
        &-more {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 15px;
            & .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 220px;
                padding: 0 15px;
                & svg {margin-left: 15px;}
            }
        }
        @media (max-width: 567px) {justify-content: space-around;}
        @media (max-width: 359px) {justify-content: space-between;}
        & .product-item {
            margin-bottom: 30px;
            @media (max-width: 567px) {margin: 0 3px 30px;}
            @media (max-width: 359px) {
                margin: 0 0px 30px;
                max-width: 137px;
            }
            @media (min-width: 658px) {
                &:not(:last-child) {margin-right: 30px; }
            }
            @media (min-width: 1101px) {
                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }
    &-about {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        padding-bottom: 90px;
        @media (max-width: 767px) {padding-bottom: 50px;}
        &-cnt {
            width: 100%;
            max-width: 824px;
            & .title {
                margin-bottom: 10px;
                @media (max-width: 767px) {margin-bottom: 20px;}
            }
        }
    }
}

.checkbox {
    &-list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: row wrap;
        &:not(:last-child) {margin-bottom: 12px;}
    }
    &-color {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        &:not(:last-child) {margin-right: 10px;}
        & span {
            display: block;
            width: 25px;
            min-width: 25px;
            height: 25px;
            background: $white;
            border: 1px solid $border;
            border-radius: 5px;
            transition: 0.3s ease;
        }
        & input {
            display: none;
            &:checked ~ span {border: 1px solid #484848; }
        }
        &.white span {background: $white;}
        &.black span {background: #3A3A3A;}
        &.gray span {background: #D8D8D8;}
        &.blue span {background: #5D97ED;}
        &.bluelight span {background: #87CCF9;}
        &.yellow span {background: #F7E95C;}
        &.green span {background: #68AC68;}
        &.purple span {background: #D10074;}
        &.orange span {background: #FF8310;}
        &.green2 span {background: #93D175;}
        &.red span {background: #CC1C10;}
        &.green3 span {background:  #8EA45B;}
    }
    &-item {
        cursor: pointer;
        display: block;
        width: 100%;
        max-width: 100px;
        min-height: 18px;
        margin-bottom: 10px;
        &:not(:last-child) {margin-right: 10px;}
        & span {
            position: relative;
            display: block;
            padding-left: 27px;
            font-family: 'Exo 2 Regular';
            font-size: 14px;
            line-height: 14px;
            color: $text;
            &::before {
                content: '';
                position: absolute;
                top: calc(50% - 9px);
                left: 0;
                display: block;
                width: 18px;
                min-width: 18px;
                height: 18px;
                background: $white;
                border: 1px solid $border;
                border-radius: 5px;
            }   
            &::after {
                content: '';
                position: absolute;
                top: calc(50% - 6px);
                left: 3px;
                display: block;
                width: 12px;
                min-width: 12px;
                height: 12px;
                background: $blue;
                border-radius: 3px;
                opacity: 0;
                transition: 0.3s ease;
            }   
        }
        & input {
            display: none;
            &:checked ~ span::after {opacity: 1; }
        }
    }
}

.filter-trigger {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    height: 40px;
    min-width: 61px;
    margin-top: 10px;
    padding: 3px 16px;
    background-color: $white;
    border: 1px solid $border;
    border-radius: 5px;
    transition: 0.3s ease;
    @media (max-width: 767px) {display: flex;}
    & span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & img {
            max-width: 18px;
            margin-right: 12px;
        }
        & span {
            font-family: 'Tahoma Regular';
            font-size: 15px;
            line-height: 15px;
            color: #456A84;
            transition: 0.3s ease;
        }
    }
    & svg {
        max-width: 10px;
        transition: 0.3s ease;
        margin-top: 2px;
    }
}