html, body {
    height: 100%;
    &.hidden {overflow: hidden;}
}

.main {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    &-content {flex: 1 0 auto;}
    & footer {
        flex: 0 0 auto;
        background: #f6f6f6;
    }
}


.wrapper {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
    @media (max-width: 1100px) {padding: 0 37px;}
    @media (max-width: 767px) {padding: 0 20px;}
}

.section-block {
    padding: 70px 0 45px;
    @media (max-width: 1100px) {
        padding: 40px 0;
    }
}


.title {
    width: 100%;
    margin-bottom: 20px;
    font-family: 'Franklin Gothic';
    font-weight: 500;
    font-size: 55px;
    line-height: 62px;
    text-transform: uppercase;
    color: $darkblue;
    &-white {color: $white;}
    &-center {text-align: center;}
    &-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;
        }
        & .title {
            width: 100%;
            padding-right: 15px;
            margin-bottom: 0;
            @media (max-width: 767px) {
                margin-bottom: 23px;
                padding-right: 0;
            }
        }
        & .link-select {
            @media (max-width: 767px) {max-width: 420px;}
        }
    }
    &-flexible {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 25px;
            & .fix-info {margin: 15px 0 0;}
        }
    }
    @media (max-width: 1100px) {
        margin-bottom: 15px;
        font-size: 30px;
        line-height: 34px;
    }
    @media (max-width: 767px) {margin-bottom: 17px;}
}

.subtitle {
    font-family: 'Exo 2 Light';
    font-size: 18px;
    line-height: 24px;
    color: $text;
    @media (max-width: 1100px) {
        font-size: 14px;
        line-height: 17px;
    }
}

.text {
    font-family: 'Exo 2 Light';
    font-size: 18px;
    line-height: 24px;
    color: $text;
    @media (max-width: 1100px) {
        font-size: 14px;
        line-height: 17px;
    }
    &:not(:last-child) {
        margin-bottom: 18px;
        @media (max-width: 1100px) {margin-bottom: 14px;}
    }
}

.fix-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-circle {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 4px 0 0;
        @media (max-width: 767px) {margin: 2px 0 0;}
        & span {
            position: relative;
            width: 15px;
            min-width: 15px;
            height: 15px;
            border: 2px solid $blue;
            border-radius: 50%;
            transition: 0.3s ease;
            @media (max-width: 1100px) {
                width: 10px;
                min-width: 10px;
                height: 10px;
                border: 1px solid $blue;
            }
            &:not(:last-child)  {
                margin-right: 22px;
                @media (max-width: 1100px) {margin-right: 10px;}
            }
            &.active {background-color: $blue;}
            & span {display: none;}
            &::before,
            &::after {
                content: '';
                position: absolute;
                top: calc(50% - 1px);
                width: 6px;
                height: 2px;
                background-color: $text;
                @media (max-width: 1100px) {
                    width: 3px;
                    height: 1px;
                }
            }
            &::before {
                right: calc(100% + 2px);
                @media (max-width: 1100px) {right: calc(100% + 1px);}
            }
            &::after  {
                left: calc(100% + 2px);
                @media (max-width: 1100px) {left: calc(100% + 1px);}
            }
            &:first-child::before,
            &:last-child::after {display: none;}
        }
    }
    &-title {
        display: inline-block;
        margin-left: 20px;
        font-family: 'Exo 2 Medium';
        font-size: 18px !important;
        line-height: 18px !important;
        text-transform: initial;
        color: $text;
        @media (max-width: 767px) {
            font-size: 14px !important;
            line-height: 14px !important;
        }
    }
}



.search-form {
    display: block;
    margin-bottom: 60px;
    @media (max-width: 1100px) {margin-bottom: 30px;}
    & form {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        @media (max-width: 550px) {
            flex-direction: column;
            align-items: center;
        }
        & .field {
            margin-right: 30px;
            @media (max-width: 550px) {margin: 0 0 20px;}
        }
        & .btn {
            @media (max-width: 550px) {max-width: 100%;}
        }
    }
}

.field {
    width: 100%;
    max-width: 730px;
    height: 45px;
    padding: 3px 20px;
    background: $white;
    border: 1px solid $border;
    border-radius: 5px;
    font-family: 'Exo 2 LightItalic';
    font-size: 18px;
    line-height: 18px;
    color: $darkblue;
    @media (max-width: 1100px) {
        padding-left: 13px;
        font-size: 14px;
        line-height: 14px;
    }
    &::-webkit-input-placeholder {color: $darkblue;}
    &::-moz-placeholder {color: $darkblue;}
    &:-ms-input-placeholder {color: $darkblue;}
    &:-moz-placeholder {color: $darkblue;}
    &:focus ~ .form-field-img svg path {fill: $blue;}
    transition: 0.3s ease;
    &:hover,
    &:focus {border: 1px solid $blue;}
}




.breadcrumbs {
    margin-bottom: 38px;
    padding: 20px 0;
    background-color: $darkblue;
    @media (max-width: 1100px) {
        padding: 12px 0 9px;
    }
    & .wrapper {
        display: flex;
        align-items: center;
        justify-items: flex-start;
        @media (max-width: 1100px) {
            flex-flow: row wrap;
        }
        & a,
        & span {
            display: inline-block;
            font-family: 'Franklin Gothic Book';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 18px;
            color: #FFFFFF;
            transition: 0.3s ease;
            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 17px;
            }
            & img {
                width: 14px;
                max-width: 14px;
                margin-right: 6px;
            }
        }
        & a,
        & .slash {
            display: flex;
            align-items: center;
            justify-items: flex-start;
            margin-right: 5px;
            font-family: 'Franklin Gothic';
        }
        & a:hover {opacity: 0.7;}
    }
}

.tabs {
    &-block {
        & .tab-cnt {
            display: none;
            &.active {display: block;}
        }
    }
    &-section {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        &-wrap {border-bottom: 1px solid $darkblue;}
        & > a {
            display: inline-block;
            height: 45px;
            padding: 0 49px;
            background: transparent;
            border-radius: 5px 5px 0px 0px;
            font-family: 'Exo 2 Medium';
            font-size: 18px;
            line-height: 45px;
            color: $darkblue;
            transition: 0.3s ease;
            @media (max-width: 767px) {display: none;}
            &:not(:last-child) {margin-right: 30px;}
            &:hover {
                @media (min-width: 1101px) {
                    color: $white;
                    background: $darkblue;
                }
            }
            &.active {
                color: $white;
                background: $darkblue;
            }
        }
        & .select-row {
            position: relative;
            width: 100%;
            max-width: 350px;
            margin: 0 auto;
            display: none;
            @media (max-width: 767px) {display: block;}
            &-active {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 45px;
                padding: 0 20px;
                font-family: 'Exo 2 Medium';
                font-size: 14px;
                line-height: 15px;
                text-align: center;
                color: $white;
                border: 1px solid $darkblue;
                background-color: $darkblue;
                border-radius: 5px 5px 0 0;
                transition: 0.3s ease;
                width: 100%;
                max-width: 350px;
                margin: 0 auto;
                & img {
                    transition: 0.3s ease;
                }
            }
            &.open {
                & img  {transform:rotate(180deg);}
            }
            &-hidden {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                z-index: 5;
                display: none;
                padding: 15px 20px;
                background-color: $darkblue;
                border-radius: 0 0 5px 5px;
                border-top: 2px solid $white;
                & a {
                    display: block;
                    font-family: 'Exo 2 Medium';
                    font-size: 14px;
                    line-height: 15px;
                    color: $white;
                    transition: 0.3s ease;
                    &:not(:last-child) {margin-bottom: 15px;}
                }
            }
        }
    }
}

.info-block {
    & table {
        width: 100%;
        max-width: 740px;
        margin-bottom: 30px;
        & tr {
            width: 100%;
            &:not(:last-child) {border-bottom: 2px solid $blue;}
            & td {
                position: relative;
                padding:16px 5px 11px;
                font-family: 'Exo 2 Bold';
                font-size: 22px;
                line-height: 27px;
                text-align: center;
                color: $text;
                @media (max-width: 767px) {
                    padding: 10px 5px 5px;
                    font-size: 12px !important;
                    line-height: 14px !important;
                }
                & span {
                    position: relative;
                    display: block;
                }
                &:first-child { 
                    max-width: 150px;
                    padding: 16px 5px 11px 0;
                    font-family: 'Exo 2 Light';
                    font-size: 16px;
                    line-height: 22px;
                    text-align: left;
                }
            }
            &:not(:last-child) {
                & td {
                    &:not(:first-child) {
                        & span {
                            &::before {
                                content: '';
                                position: absolute;
                                right: -5px;
                                bottom: -24px;
                                display: block;
                                width: 1px;
                                height: 24px;
                                background-color: $darkblue;
                            }
                        }
                    }
                    &:last-child span::before {display: none;}
                }
            }
        }
    }
    &-table {
        & table {
            & td {
                & span {
                    font-family: 'Exo 2 Light';
                    font-size: 16px;
                    line-height: 22px;
                    text-align: left;
                    @media (max-width: 767px) {
                        font-size: 12px !important;
                        line-height: 14px !important;
                    }
                    &::before {display: none;}
                }
                &:not(:first-child) {
                    & span {font-family: 'Exo 2 Bold';}
                }
            }
        }
    }
    & h5 {
        font-family: 'Franklin Gothic Book';
        font-size: 30px;
        line-height: 34px;
        text-transform: uppercase;
        color: $text;
        &:not(:last-child) {margin-bottom: 20px;}
    }
    & p,
    & b {
        font-family: 'Exo 2 Light';
        font-size: 18px;
        line-height: 24px;
        color: $darkblue;
        @media (max-width: 1100px) {
            font-size: 14px;
            line-height: 17px;
        }
        &:not(:last-child) {
            margin-bottom: 25px;
            @media (max-width: 1100px) {margin-bottom: 14px;}
        }
    }
    & b {
        font-family: 'Exo 2 Bold'; 
    }
    .fix-info {
        b {margin: 0 10px !important;}
    }
    & ul {
        margin: 0 0 40px;
        padding: 0;
        list-style: none;
        & li {
            position: relative;
            padding-left: 23px;
            font-family: 'Exo 2 Light';
            font-size: 18px;
            line-height: 24px;
            color: $darkblue;
            &::before {
                content: '';
                position: absolute;
                top: 9px;
                left: 0;
                display: block;
                width: 8px;
                height: 8px;
                background-color: $blue;
                border-radius: 50%;
            }
            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 17px;
            }
            &:not(:last-child) {margin-bottom: 5px;}
        }
    }
    & ol {
        margin: 0 0 40px;
        padding: 0 0 0 20px;
        & li {
            position: relative;
            padding-left: 5px;
            font-family: 'Exo 2 Light';
            font-size: 18px;
            line-height: 24px;
            color: $darkblue;
            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 17px;
            }
            &:not(:last-child) {margin-bottom: 5px;}
        }
    }
    & blockquote {
        padding: 25px 40px;
        margin-bottom: 45px;
        background-color: $white;
        border-left: 11px solid $blue;
        font-family: 'Exo 2 LightItalic';
        font-size: 18px;
        line-height: 24px;
        color: $darkblue;
        @media (max-width: 1100px) {
            font-size: 14px;
            line-height: 17px;
            padding: 15px;
            margin-bottom: 35px;
            border-left: 5px solid $blue;
        }
        & span {
            display: block;
            &:not(:last-child) {
                margin-bottom: 24px;
                @media (max-width: 1100px) {margin-bottom: 14px;}
            }
        }
    }
}

