.slider {
    position: relative;
    & .owl-dots {
        position: absolute;
        left: 50%;
        bottom: 13px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 1140px;
        padding: 0 15px;
        transform: translate(-50%);
        @media (max-width: 1100px) {
            bottom: 22px;
            padding: 0 37px;
        }
        @media (max-width: 767px) {
            justify-content: center;
            bottom: 11px;
            padding: 0 20px;
        }
        & button {
            position: relative;
            width: 20px;
            min-width: 20px;
            height: 20px;
            border: 2px solid $blue;
            border-radius: 50%;
            transition: 0.3s ease;
            @media (max-width: 1100px) {
                width: 10px;
                min-width: 10px;
                height: 10px;
                border: 1px solid $blue;
            }
            @media (max-width: 767px) {
                width: 12px;
                min-width: 12px;
                height: 12px;
            }
            &:not(:last-child)  {
                margin-right: 20px;
                @media (max-width: 1100px) {margin-right: 10px;}
            }
            &:hover,
            &.active {background-color: $blue;}
            & span {display: none;}
            &::before,
            &::after {
                content: '';
                position: absolute;
                top: calc(50% - 1px);
                width: 6px;
                height: 2px;
                background-color: $text;
                @media (max-width: 1100px) {
                    width: 3px;
                    height: 1px;
                }
            }
            &::before {
                right: calc(100% + 2px);
                @media (max-width: 1100px) {right: calc(100% + 1px);}
            }
            &::after  {
                left: calc(100% + 2px);
                @media (max-width: 1100px) {left: calc(100% + 1px);}
            }
            &:first-child::before,
            &:last-child::after {display: none;}
        }
    }
    & .owl-nav {
        & button {
            position: absolute;
            top: calc(50% - 16px);
            width: 32px;
            height: 32px;
            background-repeat: no-repeat !important;
            background-size: contain !important;
            transition: 0.3s ease;
            @media (max-width: 1100px) {
                top: calc(50% - 10px);
                width: 20px;
                height: 20px;
            }
            &:hover {opacity: 0.7;}
            & span {display: none;}
            &.owl-next {
                right: 20px;
                background-image: url('../images/arrow-right.svg') !important;
                @media (max-width: 1100px) {right: 10px;}
            }
            &.owl-prev {
                left: 20px;
                background-image: url('../images/arrow-left.svg') !important;
                @media (max-width: 1100px) {left: 10px;}
            }
        }
    }
}