.banner {
    width: 100%;
    background-color: #FAFAFA;
    overflow: hidden;
    &-item {
        width: 100%;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: auto 100%;
        @media (max-width: 1600px) {background-position: left -110px top;}
        @media (max-width: 450px) {
            background-position: left -110px bottom 0;
            background-size: 229px auto;
        }
        &-layout {
            width: 100%;
            background-repeat: no-repeat;
            background-position: right 263px top 20px;
            @media (max-width: 1600px) {background-position: right 0 bottom 0;}
            @media (max-width: 1100px) {
                background-position: right -95px bottom 0;
                background-size: 425px;
            }
            @media (max-width: 550px) {
                background-position: right -157px bottom -67px;
                background-size: 336px;
            }
            & .wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: flex-start;
                    padding-top: 30px;
                }
            }
        }
    }
    &-cnt {
        width: 100%;
        max-width: 370px;
        padding-bottom: 25px;
        @media (max-width: 1100px) {max-width: 260px;}
        @media (max-width: 767px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            max-width: 100%;
            padding-bottom: 18px;
        }
    }
    &-img {
        position: relative;
        width: 100%;
        max-width: 761px;
        padding-right: 30px;
        @media (max-width: 1100px) {
            max-width: 545px;
            padding-right: 15px;
        }
        & img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &-title {
        margin-bottom: 13px;
        font-family: 'Franklin Gothic';
        font-size: 70px;
        line-height: 60px;
        text-transform: uppercase;
        color: $darkblue;
        @media (max-width: 1100px) {
            margin-bottom: 16px;
            font-size: 40px;
            line-height: 40px;
        }
        @media (max-width: 767px) {
            margin-bottom: 10px;
            text-align: center;
        }
    }
    &-subtitle {
        margin-bottom: 15px;
        font-family: 'Exo 2 Light';
        font-size: 18px;
        line-height: 24px;
        color: $text;
        @media (max-width: 1100px) {
            margin-bottom: 25px;
            font-size: 14px;
            line-height: 17px;
        }
        @media (max-width: 767px) {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
        }
    }
    &-vertical {
        position: absolute;
        top: 50%;
        right: 0;
        height: 100%;
        transform: translateY(-50%) rotate(180deg);
        font-family: 'Bebas Neue';
        font-size: 30px;
        line-height: 26px;
        letter-spacing: 1px;
        color: $darkblue;
        text-align: center;
        writing-mode: vertical-lr;
        text-orientation: mixed;
        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 17px;
        }
    }
}