.product {
    &-selection {
        & .title {
            margin-bottom: 18px;
            @media (max-width: 767px) {text-align: left;}
        }
        &-card {
            padding-bottom: 100px;
            background-color: #FAFAFA;
            @media (max-width: 767px) {padding-bottom: 80px;}
            & .title {margin-bottom: 30px;}
        }
    }
    &-item {
        position: relative;
        width: 100%;
        max-width: 254px;
        background: $white;
        border: 1px solid $border;
        border-radius: 10px;
        transition: 0.3s ease;
        @media (max-width: 1100px) {max-width: 150px;}
        @media (max-width: 767px) {max-width: 146px;}
        &:hover {border: 1px solid $blue;}
        &-slider {
            width: 100%;
            & .owl-nav {
                transition: 0.3s ease;
                opacity: 0;
                @media (max-width: 1100px) {opacity: 1;}
                & button {
                    position: absolute;
                    top: calc(50% - 16px);
                    width: 50px;
                    height: 50px;
                    background-repeat: no-repeat !important;
                    background-position: center !important;
                    transition: 0.3s ease;
                    &:hover {opacity: 0.7;}
                    & span {display: none;}
                    &.owl-next {
                        right: 0;
                        background-image: url('../images/arrow-right-dark.svg') !important;
                    }
                    &.owl-prev {
                        left: 0;
                        background-image: url('../images/arrow-left-dark.svg') !important;
                    }
                }
            }
            &:hover .owl-nav {opacity: 1;}
        }
        &-hidden {
            position: absolute;
            top: calc(100% - 15px);
            left: -1px;
            display: none;
            z-index: 2;
            width: calc(100% + 2px);
            padding: 0 20px 20px;
            background-color: $white;
            border: 1px solid $blue;
            border-top: none;
            border-radius: 0 0 10px 10px;
            @media (max-width: 1100px) {display: none !important;}
            & p {
                position: relative;
                width: 100%;
                max-height: 57px;
                padding: 6px 6px 6px 25px;
                font-family: 'Exo 2 Light';
                font-size: 12px;
                line-height: 14px;
                color: $text;
                background-color: #C7E7F2;
                border-radius: 20px 0px 20px 20px;
                &::before {
                    content: '';
                    position: absolute;
                    top: calc(50% - 4px);
                    left: 11px;
                    display: block;
                    width: 8px;
                    height: 8px;
                    background-color: $blue;
                    border-radius: 50%;
                }
                &:not(:last-child) {margin-bottom: 11px;}
            }
        }
        &-img {
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 100%;
            //height: 259px;
            height: 250px;
            background-color: #C7E7F2;
            background-image: url('../images/logo-item.svg');
            background-repeat: no-repeat;
            background-position: top  right ;
            background-size: 154px;    
            border-radius: 10px 10px 0 0;
            overflow: hidden;
            /*&::before {
                content: '';
                position: absolute;
                bottom: 16px;
                left: calc(50% - 44.5px);
                display: block;
                width: 89px;
                height: 29px;
                background-image: url('../images/logo-stroke.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }*/
            @media (max-width: 1100px) {height: 150px;}
            & img {
                width: auto !important;
                max-width: 100%;
                max-height: 100%;
            }
        }
        &-cnt {
            width: 100%;
            padding: 13px 22px 25px;
            @media (max-width: 1100px) {padding: 10px 12px 25px;}
            & a {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                height: 24px;
                overflow: hidden;
                -webkit-box-orient: vertical;
                width: 100%;
                margin-bottom: 10px;
                font-family: 'Franklin Gothic';
                font-size: 24px;
                line-height: 24px;
                color: $text;
                transition: 0.3s ease;
                @media (max-width: 1100px) {
                    height: 18px;
                    margin-bottom: 15px;
                    font-size: 18px;
                    line-height: 18px;
                }
                @media (max-width: 767px) {
                    height: 16px;
                    font-size: 16px;
                    line-height: 16px;
                }
                &:hover {color: $blue;}
            }
            & p {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                height: 68px;
                overflow: hidden;
                -webkit-box-orient: vertical;
                font-family: 'Exo 2 Light';
                font-size: 14px;
                line-height: 17px;
                color: $text;
                @media (max-width: 1100px) {
                    -webkit-line-clamp: 2;
                    height: 30px;
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
    }
    &-slider {
        width: 100%;
        max-width: 1282px;
        padding: 0 90px;
        margin: 0 auto;
        @media (max-width: 1100px) {padding: 0 40px;}
        @media (max-width: 767px) {padding: 0 20px 33px;}
        & .owl-item {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & .owl-dots {
            @media (max-width: 767px) {bottom: 0;}
        }
        &-item {
            width: 100%;
            max-width: 254px;
            background: $white;
            border: 1px solid $border;
            border-radius: 10px;
            overflow: hidden;
            transition: 0.3s ease;
            @media (max-width: 1100px) {max-width: 150px;}
            @media (max-width: 767px) {max-width: 146px;}
            &:hover {border: 1px solid $blue;}
            &-img {
                position: relative;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                width: 100%;
                height: 259px;
                background-color: #C7E7F2;
                background-image: url('../images/logo-item.svg');
                background-repeat: no-repeat;
                background-position: top  right ;
                background-size: 154px;
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 16px;
                    left: calc(50% - 44.5px);
                    display: block;
                    width: 89px;
                    height: 29px;
                    background-image: url('../images/logo-stroke.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                }
                @media (max-width: 1100px) {height: 150px;}
                & img {
                    width: auto !important;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &-cnt {
                width: 100%;
                padding: 13px 22px 25px;
                @media (max-width: 1100px) {padding: 10px 12px 25px;}
                & a {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    height: 24px;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    width: 100%;
                    margin-bottom: 10px;
                    font-family: 'Franklin Gothic';
                    font-size: 24px;
                    line-height: 24px;
                    color: $text;
                    transition: 0.3s ease;
                    @media (max-width: 1100px) {
                        height: 18px;
                        margin-bottom: 15px;
                        font-size: 18px;
                        line-height: 18px;
                    }
                    @media (max-width: 767px) {
                        height: 16px;
                        font-size: 16px;
                        line-height: 16px;
                    }
                    &:hover {color: $blue;}
                }
                & p {
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    height: 68px;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    font-family: 'Exo 2 Light';
                    font-size: 14px;
                    line-height: 17px;
                    color: $text;
                    @media (max-width: 1100px) {
                        -webkit-line-clamp: 2;
                        height: 30px;
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }
        }
    }
}