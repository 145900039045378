.contact {
  .title{
    margin-bottom: 40px;
  }
  &-box {
    width: 100%;
    margin-bottom: 30px;
    background: #ffffff;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 30px;
    &_2 &{
      &__item{
        width: calc(50% - 30px);
      }
    }
    &_3 &{
      &__item{
        width: calc(33.3333% - 30px);
      }
    }
    &__item {
      margin: 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }
    a{
      display: block;
      font-family: 'Exo 2 Regular';
      font-size: 16px;
      line-height: 16px;
      color: #456A84;
      transition: 0.3s ease;
      margin-bottom: 10px;
      &:hover{
        color: #00B1CE;
      }
    }
    img{
      margin-bottom: 15px;
    }
  }
  &-item {
    margin-bottom: 30px;
  }
  &-row {
    display: flex;
    flex-direction: column;
  }
  &__title {
    margin-bottom: 10px;
    font-family: 'Franklin Gothic';
    font-size: 24px;
    line-height: 24px;
    color: #456A84;
    &_small {
      font-size: 18px;
      line-height: 18px;
    }
  }

  @media (max-width: 767px) {
    &-box{
      padding: 15px;
      &_2 &{
        &__item{
          width: calc(100% - 30px);
        }
      }
      &_3 &{
        &__item{
          width: calc(100% - 30px);
        }
      }
    }
  }
}
