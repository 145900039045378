.pain {
    padding: 85px 0 38px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 1920px 745px;
    @media (max-width: 1360px) {
        padding:93px 0 4px;
        background-size: cover;
    }
    @media (max-width: 767px) {
        padding: 175px 0 4px;
        background-position: bottom -66px center;
    }
    & .wrapper {position: relative;}
    &-title {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        font-family: 'Franklin Gothic';
        font-size: 230px;
        line-height: 261px;
        text-align: center;
        color: $white;
        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        @media (max-width: 1360px) {
            font-size: 140px;
            line-height: 159px;
        }
        @media (max-width: 767px) {
            font-size: 70px;
            line-height: 79px;
        }
    }
    &-block {
        display: inline-block;
        position: relative;
        z-index: 2;
        @media (max-width: 1360px) {
            display: block;
            width: 100%;
            max-width: 710px;
            margin: 0 auto;
        }
        @media (max-width: 767px) {max-width: 280px;}
    }
    &-img {max-width: 100%;}
    &-info {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media (max-width: 767px) {
            position: static;
        }
        &-hidden {
            position: relative;
            z-index: -1;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            margin-right: 15px;
            width: 100%;
            max-width: 403px;
            background: #97D9E6;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
            border-radius: 50px 0 50px 50px;
            transition: 0.3s ease;
            overflow: hidden;
            opacity: 0;
            @media (max-width: 1360px) {
                max-width: 350px;
            }
            @media (max-width: 767px) {
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                max-width: 280px;
                width: 280px;
            }
            &-img {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                width: 100%;
                max-width: 144px;
                min-width: 140px;
                padding-left: 23px;
                margin-right: 5px;
                @media (max-width: 1360px) {
                    max-width: 130px;
                    min-width: 130px;
                    padding-left: 6px;
                }
                @media (max-width: 767px) {
                    max-width: 100px;
                    min-width: 100px;
                    padding-left: 5px;
                    margin-right: 16px;
                }
                & img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &-cnt {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;
                padding: 13px 26px 20px 5px;
                @media (max-width: 767px) {
                    align-items: center;
                    max-width: 280px;
                    padding: 10px 15px 15px 2px;
                }
                & p {
                    width: 100%;
                    margin-bottom: 21px;
                    font-family: 'Tahoma Regular';
                    font-size: 14px;
                    line-height: 17px;
                    color: $text;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    height: 68px;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    @media (max-width: 1360px) {
                        margin-bottom: 15px;
                        font-size: 12px;
                        line-height: 17px;
                    }
                }
                & .btn-dashed {
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        right: calc(100% - 2px);
                        bottom: 19px;
                        display: block;
                        width: 100%;
                        max-width: 101px;
                        height: 33px;
                        background-image: url('../images/arrow-simple.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        @media (max-width: 1360px) {
                            bottom: 13px;
                        }
                        @media (max-width: 767px) {
                            max-width: 55px;
                            height: 33px;
                            background-image: url('../images/arrow-simple2.svg');
                        }
                    }
                }
            }
        }
        &.active {
            & .pain-info-hidden {
                opacity: 1;
                z-index: 3;
            }
            & .pain-info-trigger {
                color: $blue;
                border-bottom: 1px solid $blue;
                & span::before {background-color: $blue;}
                &::after {background-color: $blue;}
                &::before {
                    background-color: $blue;
                    box-shadow: 0 0 30px 20px rgba(0, 177, 206, 0.49);
                }
            }
        }
        &-trigger {
            cursor: pointer;
            position: relative;
            z-index: 2;
            display: inline-block;
            font-family: 'Exo 2 Light';
            font-size: 20px;
            line-height: 24px;
            text-align: justify;
            color: $darkblue;
            border-bottom: 1px solid $darktext;
            transition: 0.3s ease;
            @media (max-width: 767px) {
                position: absolute;
                font-size: 12px;
                line-height: 17px;
            }
            & span {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    bottom: -1px;
                    left: 100%;
                    display: block;
                    width: 17px;
                    height: 1px;
                    background-color: $darktext;
                    transition: 0.3s ease;
                }
            }
            &::after {
                content: '';
                position: absolute;
                display: block;
                height: 1px;
                background-color: $darktext;
                transition: 0.3s ease;
            }
            &::before {
                content: '';
                position: absolute;
                bottom: -26px;
                z-index: 2;
                left: calc(100% + 38px);
                display: block;
                width: 13px;
                height: 13px;
                background-color: $blue;
                border-radius: 50%;
                transition: 0.3s ease;
                @media (max-width: 767px) {
                    width: 15px;
                    height: 15px;
                }
            }
            // &:hover::before {box-shadow: 0 0 30px 20px rgba(0, 177, 206, 0.49);}
        }
        &-1 {
            top: 75px;
            left: -121px;
            @media (max-width: 1360px) {
                flex-direction: column;
                top: -77px;
                left: 23px;
                & .pain-info-hidden {margin: 0 0 15px;}
            }
            & .pain-info-trigger {
                @media (max-width: 767px) {
                    top: 7px;
                    left: 45px;
                    & span {
                        &::before {display: none;}
                    }
                }
                &::before {
                    @media (max-width: 767px) {
                        bottom: -34px;
                        left: calc(100% + 17px);
                    }
                }
                &::after {
                    bottom: -11px;
                    left: calc(100% + 13px);
                    width: 35px;
                    transform: rotate(34.59deg);
                    @media (max-width: 767px) {
                        bottom: -14px;
                        left: calc(100% + -6px);
                        width: 35px;
                        transform: rotate(48.59deg);
                    }
                }
            }
        }
        &-2 {
            top: 100px;
            right: -15px;
            @media (max-width: 1360px) {
                top: 94px;
                right: 90px;
                flex-direction: column;
            }
            & .pain-info-trigger {
                @media (max-width: 767px) {
                    top: 47px;
                    left: 191px;
                }
                &::before {
                    bottom: 14px;
                    left: auto;
                    right: calc(100% + 30px);
                }
                &::after {
                    bottom: 8px;
                    left: auto;
                    width: 38px;
                    right: calc(100% - 3px);
                    transform: rotate(29.59deg);
                }
                & span {
                    &::before {display: none;}
                }
            }
            & .pain-info-hidden {
                margin: 0 0 0 15px;
                @media (max-width: 1360px) {margin: 15px 0 0;}
            }
        }
        &-3 {
            top: 250px;
            right: 68px;
            @media (max-width: 1360px) {
                top: 212px;
                right: 159px;
                flex-direction: column;
            }
            & .pain-info-trigger {
                @media (max-width: 767px) {
                    top: 72px;
                    left: 144px;
                }
                &::before {
                    bottom: auto;
                    top: -43px;
                    left: auto;
                    right: calc(100% + 53px);
                    @media (max-width: 767px) {
                        bottom: auto;
                        top: -3px;
                        left: auto;
                        right: calc(100% + 26px);
                    }
                }
                &::after {
                    bottom: 27px;
                    left: auto;
                    width: 80px;
                    right: calc(100% - 12px);
                    transform: rotate(44.59deg);
                    @media (max-width: 767px) {
                        bottom: 4px;
                        left: auto;
                        width: 31px;
                        right: calc(100% - 1px);
                        transform: rotate(19.59deg);
                    }
                }
                & span {
                    &::before {display: none;}
                }
            }
            & .pain-info-hidden {
                margin: 0 0 0 15px;
                @media (max-width: 1360px) {margin: 15px 0 0;}
            }
        }
        &-4 {
            top: 367px;
            right: 28px;
            @media (max-width: 1360px) {
                top: 282px;
                right: 166px;
                flex-direction: column;
            }
            & .pain-info-trigger {
                @media (max-width:  767px) {
                    bottom: 41px;
                    right: 70px;
                }
                &::before {
                    bottom: auto;
                    top: 11px;
                    left: auto;
                    right: calc(100% + 34px);
                    @media (max-width:  767px) {
                        top: 6px;
                        right: calc(100% + 9px);
                    }
                }
                &::after {
                    bottom: 2px;
                    left: auto;
                    width: 40px;
                    right: calc(100% - 0px);
                    transform: rotate(8deg);
                    @media (max-width:  767px) {
                        bottom: 0px;
                        width: 17px;
                    
                    }
                }
                & span {
                    &::before {display: none;}
                }
            }
            & .pain-info-hidden {
                margin: 0 0 0 15px;
                @media (max-width: 1360px) {margin: 15px 0 0;}
            }
        }
        &-5 {
            top: auto;
            bottom: 227px;
            left: -33px;
            flex-direction: column;
            @media (max-width: 1360px) {
                bottom: 167px;
                left: 39px;
                align-items: flex-start;
            }
            & .pain-info-hidden {margin: 0 0 15px;}
            & .pain-info-trigger {
                @media (max-width: 767px) {
                    bottom: 71px;
                    left: -18px;
                    & span {
                        &::before {display: none;}
                    }
                }
                &::after {
                    bottom: -12px;
                    left: calc(100% + 14px);
                    width: 60px;
                    transform: rotate(21.59deg);
                    @media (max-width: 767px) {
                        bottom: -4px;
                        width: 37px;
                        left: calc(100% + -1px);
                        transform: rotate(10.59deg);
                    }
                }
                &::before {
                    bottom: -27px;
                    left: calc(100% + 62px);
                    @media (max-width: 767px) {
                        bottom: -16px;
                        left: calc(100% + 29px);
                    }
                }
            }
        }
        &-6 {
            bottom: 20px;
            right: 166px;
            flex-direction: column;
            @media (max-width: 1360px) {
                bottom: 13px;
                right: 54px;
            }
            & .pain-info-trigger {
                @media (max-width: 767px) {
                    bottom: -3px;
                    right: 59px;
                    & span {
                        &::before {display: none;}
                    }
                }
                &::before {
                    bottom: 22px;
                    left: auto;
                    right: calc(100% + 33px);
                    @media (max-width: 767px) {
                        bottom: 12px;
                        left: auto;
                        right: calc(100% + 3px);
                    }
                }
                &::after {
                    bottom: 11px;
                    left: auto;
                    width: 43px;
                    right: calc(100% - 5px);
                    transform: rotate(35.59deg);
                    @media (max-width: 767px) {
                        bottom: 10px;
                        left: auto;
                        width: 27px;
                        right: calc(100% - 6px);
                        transform: rotate(58.59deg);
                    }
                }
                & span {
                    &::before {display: none;}
                }
            }
            & .pain-info-hidden {
                margin: 0 0 15px;
            }
        }
        &-7 {
            bottom: 106px;
            right: 15px;
            flex-direction: column;
            align-items: flex-end;
            @media (max-width: 1360px) {
                bottom: 100px;
                right: 131px;
            }
            & .pain-info-trigger {
                @media (max-width: 767px) {
                    bottom: 39px;
                    right: 0;
                    & span {
                        &::before {display: none;}
                    }
                }
                &::before {
                    bottom: -27px;
                    left: auto;
                    right: calc(100% + 46px);
                    @media (max-width: 1360px) {
                        bottom: -37px;
                        right: -30px;
                    }
                    @media (max-width: 767px) {
                        bottom: -20px;
                        right: 41px;
                    }
                }
                &::after {
                    bottom: -11px;
                    left: auto;
                    width: 55px;
                    right: calc(100% - 2px);
                    transform: rotate(-20.59deg);
                    @media (max-width: 1360px) {
                        bottom: -16px;
                        left: calc(100% - 8px);
                        width: 40px;
                        right: auto;
                        transform: rotate(51.59deg);
                    }
                    @media (max-width: 767px) {
                        bottom: -8px;
                        left: auto;
                        width: 22px;
                        right: calc(100% + -3px);
                        transform: rotate(-40.59deg);
                    }
                }
                & span {
                    &::before {display: none;}
                }
            }
            & .pain-info-hidden {
                margin: 0 0 15px;
            }
        }
    }
}